import React from "react";
import Feature from "./pages/Apps/Feature";

// Root Include
// const Root = React.lazy(() => import("./pages/Home/indexRoot"));

const ClassicApp = React.lazy(() =>
    import ("./pages/Freelancer/index")
);

const routes = [
    //routes without Layout
    { path: "/", component: ClassicApp, isTopbarDark: true },
    { path: "feature", component: Feature },
];

export default routes;
