// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import FeatureBox from "./feature-box";

//import images
import featureImg from "../../assets/images/app/features.png";

class Feature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [{
                    icon: "monitor",
                    title: "Projets",
                    desc: "Cette application vous propose une fonctionalite de taille qui est la gestion de vos projets agricoles entre autre choisir un champ et selectionner parmi les cultures appropries a celui ci , voir les details necessaire a l’application du projet.",
                },
                {
                    icon: "feather",
                    title: "Champs",
                    desc: "Vous avez la possibilite d’ajouter vos champs dans la plateforme, les mettre en location, les assigner a un projet et aussi voir les details les concernant entre autre les cultures precedentes, les activites actuelles, etc",
                },
                {
                    icon: "eye",
                    title: "Materiels",
                    desc: "La plateforme vous accompagne aussi dans la gestion de vos materiels et vous donne la possibilite de les mettre en locations pour d’autres projets.",
                },
                {
                    icon: "user-check",
                    title: "Personnel",
                    desc: "La gestion du personel etant tres crucial pour l’evolution d’un projet, nous vous offrons non seulement la possibilite de gerer vos ressources humaines, d’assigner des taches a ces personnes mais aussi l’opportunite de collaborer avec d’autres entreprises ou des particuliers qui travaillent sur d’autres projets selon vos besoins.",
                },
                {
                    icon: "smartphone",
                    title: "Transactions",
                    desc: "La tracabilite de vos finances est aussi un atout que notre plateforme met a votre disposition, vous pouvez controler les transactions lie a n’importe quel projet dans lequel vous etes implique.",
                },
            ],
        };
    }

    render() {
        return ( 
          <React.Fragment >
            <section className = "section bg-light" id="feature">
            <Container > { /* section title */ } <SectionTitle 
            title = "Fonctionalites"
            desc = "qui peut fournir tout ce dont vous avez besoin pour générer de la notoriété, générer du trafic, vous connecter." /
            >

            <Row className = "justify-content-center align-items-center" >
              <Col >
            <Row>
            <FeatureBox features = { this.state.features }/>  
            </Row > 
              </Col>   
            </Row > 
            </Container>  
            </section > 
            </React.Fragment>
        );
    }
}

export default Feature;